<template>
  <top-nav-theme0 v-if="kindTheme === 0"/>
  <top-nav-theme1 v-else-if="kindTheme === 1"/>
  <top-nav-theme2 v-else-if="kindTheme === 2"/>
</template>

<script>

export default {
  name: 'TopNav',
  components: {
    TopNavTheme0: () => import('@/components/content/TopNav/TopNavTheme0'),
    TopNavTheme1: () => import('@/components/content/TopNav/TopNavTheme1'),
    TopNavTheme2: () => import('@/components/content/TopNav/TopNavTheme2'),
  },
  props: {
    kindTheme: {
      type: Number,
      default: 0,
    },
  },
};
</script>
